// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    Alert,
  } from "reactstrap";


import Header from "components/Headers/Header.js"
import React from "react"
import { createScrape } from "api";
import { useHistory } from 'react-router-dom';

const NewScrape = () => {
    const history = useHistory()

    const [errorMessage, setErrorMessage] = React.useState('');
    const [errorField, setErrorField] = React.useState('');

    const onFormSubmit = async (event) => {
        event.preventDefault();

        setErrorMessage('');
        setErrorField('');

        const formData = new FormData(event.currentTarget);
        const scrapeType = formData.get('scrape-type');
        const keyword = formData.get('keyword');
        let maxResults = formData.get('max-results');

        if (!scrapeType) {
            setErrorMessage('Please select a scrape type');
            setErrorField('scrape-type');
            return;
        }

        if (!keyword) {
            setErrorMessage('Please enter a keyword');
            setErrorField('keyword');
            return;
        }

        if (!maxResults) {
            setErrorMessage('Please enter a max results');
            setErrorField('max-results');
            return;
        }

        maxResults = parseInt(maxResults, 10)
        if (maxResults < 1) {
            setErrorMessage('Max results must be greater than 0');
            setErrorField('max-results');
            return;
        }
        if (maxResults > 20000) {
            setErrorMessage('Max results must be less than 20,000');
            setErrorField('max-results');
            return;
        }

        try {
            const result = await createScrape(scrapeType, keyword, maxResults)
            history.push(`/dashboard/index?hl=${result.request_id}`)

        } catch (error) {
            console.log(error);
            setErrorMessage('An error occurred. Try again?')
        }
    }

    return (
        <>
        <Header />
        <Container className="mt--7">
            <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">New Scrape</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
              {errorMessage &&
                <Alert className="alert alert-danger" role="alert">
                    <strong>Error:</strong> {errorMessage}
                </Alert>
              }
                <Form role="form" onSubmit={onFormSubmit}>
                  <div className="pl-lg-4">
                    <Row>
                      <Col md="12">
                        <FormGroup className={errorField === 'scrape-type' ? "has-danger" : "" }>
                          <label
                            className="form-control-label"
                            htmlFor="scrape-type"
                          >
                            Search For...
                          </label>
                          <Input
                            type="select"
                            name="scrape-type"
                            required={true}
                            className={errorField === 'scrape-type' ? "is-invalid" : "" }>
                                <option value="">Choose one..</option>
                                <option value="followers">Followers</option>
                                <option value="following">Following</option>
                                <option value="likes">Likes</option>
                                <option value="hashtag">Hashtag</option>
                            </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup className={errorField === 'keyword' ? "has-danger" : "" }>
                          <label
                            className="form-control-label"
                            htmlFor="keyword"
                          >
                            Keyword
                          </label>
                          <Input
                            type="text"
                            name="keyword"
                            placeholder="selenagomez"
                            required={true}
                            className={errorField === 'keyword' ? "is-invalid" : "" }
                            />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup className={errorField === 'max-results' ? "has-danger" : "" }>
                          <label
                            className="form-control-label"
                            htmlFor="max-results"
                          >
                            Max Results
                          </label>
                          <Input
                            type="number"
                            name="max-results"
                            pattern="\d+"
                            placeholder="1000"
                            step="1"
                            required={true}
                            className={errorField === 'max-results' ? "is-invalid" : "" }
                            />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <div className="text-center">
                    <Button className="my-4" color="primary" type="submit">
                    Start Scraping!
                    </Button>
                </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
            </Row>
        </Container>
        </>
  );
};

export default NewScrape;