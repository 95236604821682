// reactstrap components
import {
    Alert,
    Badge,
    Spinner,
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    Row,
    UncontrolledTooltip,
    Button,
  } from "reactstrap";
  // core components
  import Header from "components/Headers/Header.js";
  
import React, { useEffect } from "react"
import { getScrapes, getScrapeDownloadUrl } from "api";
import { formatDistance } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'

function useQueryParams() {
    const params = new URLSearchParams(
      window ? window.location.search : {}
    );

    return new Proxy(params, {
        get(target, prop) {
            return target.get(prop)
        },
    });
}

const ListScrapes = () => {
    const download = async (e, id) => {
        e.preventDefault();

        const downloadUrl = await getScrapeDownloadUrl(id);
        console.log(downloadUrl);

        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = downloadUrl;
        document.body.appendChild(a)
        a.click();
        document.body.removeChild(a);
    }
    const [scrapeData, setScrapeData] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);

    useEffect(() => {
        async function getData() {
            const results = await getScrapes();
            setScrapeData(results.results);
            setIsLoading(false);
        }

        getData();
    }, []);

    const { hl, ...unknown } = useQueryParams();

    return (
        <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Scrapes</h3>
              </CardHeader>

            {isLoading &&
              <button class="btn btn-primary btn-lg mb-2" type="button" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...
              </button>
            }
            {!isLoading && !scrapeData.length &&
                <Alert color="warning" className="text-center">
                    You haven't created any scrapes yet. <a href="/dashboard/scrape" class="text-secondary"><strong>Create one now.</strong></a>
                </Alert>
            }
            {!isLoading && scrapeData.length && 
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Type</th>
                    <th scope="col">Created</th>
                    <th scope="col">Keyword</th>
                    <th scope="col">Max Results</th>
                    <th scope="col">Status</th>
                    <th scope="col">Results</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                    {scrapeData.map((result) => {
                        const now = Date.now()
                        const createdDate = new Date(result.created_at * 1000)
                        return <tr key={result.id} class={hl === result.id ? "bg-light" : ""}>
                            <th scope="row">
                                <span className="mb-0 text-sm">
                                    {result.request_type}
                                </span>
                            </th>
                            <td>
                                {formatDistance(createdDate, now, { includeSeconds: true, addSuffix: true })}
                            </td>
                            <td>
                                {result.search_keyword}
                            </td>
                            <td>
                                {result.max_results}
                            </td>
                            <td>
                                {result.status}
                            </td>
                            <td>
                                {result.num_results}
                            </td>
                            <td className="">
                                {result.status === 'completed' &&
                                    <Button color="link" onClick={(e) => download(e, result.id)}>
                                        <FontAwesomeIcon icon={faDownload} className="" />
                                    </Button>
                                    
                                    // <UncontrolledDropdown>
                                    //     <DropdownToggle
                                    //         className="btn-icon-only text-light"
                                    //         role="button"
                                    //         size="sm"
                                    //         color=""
                                    //         >
                                    //         <i className="fas fa-ellipsis-v" />
                                    //     </DropdownToggle>
                                    //     <DropdownMenu className="dropdown-menu-arrow" right>
                                    //     <DropdownItem
                                    //         href="#pablo"
                                    //         onClick={(e) => download(e, result.id)}
                                    //     >
                                    //         Download
                                    //     </DropdownItem>
                                    //     </DropdownMenu>
                                    // </UncontrolledDropdown>
                                }
                            </td>
                        </tr>
                    })}
                </tbody>
              </Table>
            }
            </Card>
          </div>
        </Row>
      </Container>
        </>
    )
}

export default ListScrapes