/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Alert,
} from "reactstrap";
import { useHistory } from 'react-router-dom';
import React from 'react';
import { attemptAuthenticate, popupGoogleLogin, sendEmailVerification } from 'auth'

const Login = () => {
  const history = useHistory()
  const [errorMessage, setErrorMessage] = React.useState('')

  const loginWithGoogle = async (e) => {
    try {
      const credential = await popupGoogleLogin();
      history.push('/dashboard')
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
    }
  }

  const attemptLogin = async(event) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const email = formData.get('email');
    const password = formData.get('password');

    if (!email) {
      setErrorMessage('Please enter your email address')
      return;
    }

    if (!password) {
      setErrorMessage('Please enter a password')
      return;
    }

    try {
      const cred = await attemptAuthenticate(email, password)
      if (cred.user.emailVerified) {  // TODO: this should be in router
        history.push('/dashboard')
      } else {
        await sendEmailVerification(cred.user);
        setErrorMessage('Email address not verified. Please check your email for a verification link.');
      }
    } catch (error) {
      switch (error.code) {
        case 'auth/user-not-found':
          setErrorMessage('Email address not found');
          break;
        case 'auth/wrong-password':
          setErrorMessage('Wrong password');
          break;
        default:
          setErrorMessage(error.message)
      }
    }

  }

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-5">
            <div className="text-muted text-center mt-2 mb-3">
              <small>Sign in with</small>
            </div>
            <div className="btn-wrapper text-center">
              <Button
                className="btn-neutral btn-icon"
                color="default"
                onClick={loginWithGoogle}
              >
                <span className="btn-inner--icon">
                  <img
                    alt="..."
                    src={
                      require("../../assets/img/icons/common/google.svg")
                        .default
                    }
                  />
                </span>
                <span className="btn-inner--text">Google</span>
              </Button>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Or sign in with credentials</small>
            </div>
            {errorMessage &&
              <Alert className="alert alert-danger" role="alert">
                <strong>Error:</strong> {errorMessage}
              </Alert>
            }
            <Form role="form" onSubmit={attemptLogin}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    name="email"
                    autoComplete="email"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    name="password"
                    type="password"
                    autoComplete="off"
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button className="my-4" color="primary" type="submit">
                  Sign in
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <a
              className="text-light"
              href="/auth/forgot-password"
            >
              <small>Forgot password?</small>
            </a>
          </Col>
          <Col className="text-right" xs="6">
            <a
              className="text-light"
              href="/auth/register"
            >
              <small>Register</small>
            </a>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Login;
