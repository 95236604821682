import { getToken } from "auth";

const baseUrl = 'https://api.emailsfrominstagram.com';

const makeRequest = async (endpoint, method, body) => {
    const token = await getToken();

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    }
    const opts = {
        method,
        headers
    }
    if (method === 'POST') {
        opts.body = JSON.stringify(body)
    }

    const results = await fetch(`${baseUrl}/${endpoint}`, opts);

    return await results.json()
}
const getScrapes = async () => {
    return await makeRequest('scrapes', 'GET');
}
const createScrape = async (type, keyword, max_results) => {
    const body = {
        type,
        keyword,
        max_results
    };

    return await makeRequest('scrapes', 'POST', body);
}

const getScrapeDownloadUrl = async (request_id) => {
    const result = await makeRequest(`scrapes/${request_id}/download`, 'GET');
    return result.url;
}

export {
    getScrapes,
    createScrape,
    getScrapeDownloadUrl
}