import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Alert,
  } from "reactstrap";
  import { useHistory } from 'react-router-dom';
  import React from 'react';
  import { attemptSendPasswordReset } from 'auth'

  const ForgotPassword = () => {
    const [errorMessage, setErrorMessage] = React.useState('')
    const [isSuccess, setIsSuccess] = React.useState(false)

    const onFormSubmit = async(event) => {
        event.preventDefault();

        setErrorMessage('');

        const formData = new FormData(event.currentTarget);
        const email = formData.get('email');

        if (!email) {
            setErrorMessage('Please enter an email address')
            return;
        }

        try {
            await attemptSendPasswordReset(email)
            setIsSuccess(true);
        } catch (error) {
            const errorCode = error.code;
            const errorMessage = error.message;
            setErrorMessage(errorMessage)
        }
        


    }
    return (
        <>
        <Col lg="5" md="7">
            <Card className="bg-secondary shadow border-0">
                <CardBody className="px-lg-5 py-lg-5">
                    {isSuccess && 
                    <Alert className="alert alert-success" role="alert">
                        <strong>Success:</strong> check your email for a reset link
                    </Alert>
                    }
                    {!isSuccess && 
                    <>
                        <div className="text-center text-muted mb-4">
                            <small>Enter your email address to receive a password reset email</small>
                        </div>
                        {errorMessage &&
                        <Alert className="alert alert-danger" role="alert">
                            <strong>Error:</strong> {errorMessage}
                        </Alert>
                        }
                        <Form role="form" onSubmit={onFormSubmit}>
                            <FormGroup className="mb-3">
                                <InputGroup className="input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                    <i className="ni ni-email-83" />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    placeholder="Email"
                                    type="email"
                                    name="email"
                                    autoComplete="email"
                                />
                                </InputGroup>
                            </FormGroup>
                            <div className="text-center">
                                <Button className="my-4" color="primary" type="submit">
                                Send Reset Email
                                </Button>
                            </div>
                        </Form>
                    </>
                    }
                </CardBody>
            </Card>
        </Col>
        </>
    )
  }

  export default ForgotPassword;