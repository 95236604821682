/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";

import { popupGoogleLogin, attemptSignup } from 'auth'
import { useHistory } from 'react-router-dom';
import React from "react";

const Register = () => {
  const history = useHistory()

  const [success, setSuccess] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(false);
  const [isEmailError, setIsEmailError] = React.useState(false);
  const [isPasswordError, setIsPasswordError] = React.useState(false);
  const [isConfirmPasswordError, setConfirmPasswordError] = React.useState(false);

  const loginWithGoogle = async (e) => {
    try {
      const credential =  await popupGoogleLogin()
      history.push('/dashboard')
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage('')
    setIsEmailError(false)
    setIsPasswordError(false)
    setConfirmPasswordError(false)

    const formData = new FormData(event.currentTarget);

    const email = formData.get('email');
    if (!email) {
      setIsEmailError(true)
      setErrorMessage('Please enter your email address')
      return;
    }

    const password = formData.get('password');
    if (!password) {
      setIsPasswordError(true)
      setErrorMessage('Please enter a password')
      return;
    }

    const confirmPassword = formData.get('confirmPassword');
    if (confirmPassword !== password) {
      setConfirmPasswordError(true)
      setErrorMessage('Passwords do not match')
      return;
    }
    
    try {
      await attemptSignup(email, password)
      setSuccess(true)
    } catch(error) {
        const errorCode = error.code;
        console.log(errorCode)

        switch(errorCode) {
          case 'auth/invalid-email':
            setErrorMessage('Please enter a valid email address')
            setIsEmailError(true)
            break;
          case 'auth/email-already-in-use':
            setErrorMessage('That email address is already in use')
            setIsEmailError(true)
            break;
          case 'auth/weak-password':
            setErrorMessage('The password is too weak')
            setIsPasswordError(true)
            break;
          default:
            setErrorMessage(error.message)
            return
        }    
      }
  }

  return (
    <>
      <Col lg="6" md="8">
        <Card className="bg-secondary shadow border-0">
        <CardHeader className="bg-transparent pb-5">
            <div className="text-muted text-center mt-2 mb-3">
              <small>Sign in with</small>
            </div>
            <div className="btn-wrapper text-center">
              <Button
                className="btn-neutral btn-icon"
                color="default"
                href="#pablo"
                onClick={loginWithGoogle}
              >
                <span className="btn-inner--icon">
                  <img
                    alt="..."
                    src={
                      require("../../assets/img/icons/common/google.svg")
                        .default
                    }
                  />
                </span>
                <span className="btn-inner--text">Google</span>
              </Button>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            {success &&
              <Alert className="alert alert-success" role="alert">
              <strong>Success:</strong> check your email for a confirmation link
            </Alert>
            }
            
            {errorMessage &&
              <Alert className="alert alert-danger" role="alert">
                <strong>Error:</strong> {errorMessage}
              </Alert>
            }
            {!success &&
            <Form role="form" onSubmit={handleSubmit}>
              <FormGroup className={isEmailError ? "has-danger" : "" }>
                {/* <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon> */}
                  <Input
                    name="email"
                    placeholder="Email"
                    type="email"
                    required={true}
                    className={isEmailError ? "is-invalid" : "" }
                  />
                {/* </InputGroup> */}
              </FormGroup>
              <FormGroup className={isPasswordError ? "has-danger" : "" }>
                {/* <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon> */}
                  <Input
                    name="password"
                    placeholder="Password"
                    type="password"
                    autocomplete="off"
                    required={true}
                    className={isPasswordError ? "is-invalid" : "" }
                  />
                {/* </InputGroup> */}
              </FormGroup>
              <FormGroup className={isConfirmPasswordError ? "has-danger" : "" }>
                {/* <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon> */}
                  <Input
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    type="password"
                    autocomplete="off"
                    required={true}
                    className={isConfirmPasswordError ? "is-invalid" : "" }
                  />
                {/* </InputGroup> */}
              </FormGroup>
              <div className="text-center">
                <Button className="mt-4" color="primary" type="submit">
                  Create account
                </Button>
              </div>
            </Form>
            }
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Register;
