// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, signInWithEmailAndPassword, signOut, createUserWithEmailAndPassword, sendEmailVerification, sendPasswordResetEmail, onAuthStateChanged } from "firebase/auth";
import { GoogleAuthProvider } from "firebase/auth";
let token = '';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDzww44AUztdKK_lCTKn9w-xWqDbYci57g",
  authDomain: "emailsfrominstagram-c134d.firebaseapp.com",
  projectId: "emailsfrominstagram-c134d",
  storageBucket: "emailsfrominstagram-c134d.appspot.com",
  messagingSenderId: "471296465771",
  appId: "1:471296465771:web:ba45368070ee1d4f1aca2e"
};

// Initialize Firebase
const fire = initializeApp(firebaseConfig);

const popupGoogleLogin = async () => {
    const auth = getAuth(fire);
    
    const provider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, provider);

    const credential = GoogleAuthProvider.credentialFromResult(result);
    return credential;
}
const attemptAuthenticate = async(email, password) => {
    const auth = getAuth(fire);
    return signInWithEmailAndPassword(auth, email, password)
}
const attemptSignup = async(email, password) => {
    const auth = getAuth(fire);
    const creds = await createUserWithEmailAndPassword(auth, email, password)
    await sendEmailVerification(creds.user);
    return true;
}
const logout = async() => {
    const auth = getAuth(fire);
    await signOut(auth)
    return true;
}

const attemptSendPasswordReset = async(email) => {
    const auth = getAuth(fire);
    await sendPasswordResetEmail(auth, email)
}

const getToken = async() => {
    const sleep = t => new Promise(s => setTimeout(s, t, t));
    // fire.auth()
    let currentUser;
    do {
        const auth = getAuth(fire);
        currentUser = auth.currentUser;

        if (currentUser) {
            break
        }

        await sleep(500)
    }
    while (true)

    console.log('currentUser', currentUser);
    const token = await currentUser.getIdToken();
    console.log('token', token)
    return token;
}

export {
    signOut,
    attemptSignup,
    attemptAuthenticate,
    popupGoogleLogin,
    logout,
    attemptSendPasswordReset,
    sendEmailVerification,
    getToken,
}