/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "views/Index.js";
import Profile from "views/examples/Profile.js";
import Register from "views/examples/Register.js";
import Login from "views/examples/Login.js";
import ListScrapes from "views/list_scrapes";
import NewScrape from "views/new_scrape";
import ForgotPassword from "views/ForgotPassword.js"

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: ListScrapes,
    layout: "/dashboard",
  },
  // {
  //   path: "/scrapes",
  //   name: "Scrapes",
  //   icon: "ni ni-bullet-list-67 text-red",
  //   component: ListScrapes,
  //   layout: "/dashboard",
  // },
  {
    path: "/scrape",
    name: "New Scrape",
    icon: "ni ni-camera-compact text-blue",
    component: NewScrape,
    layout: "/dashboard",
  },
  // {
  //   path: "/user-profile",
  //   name: "User Profile",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: Profile,
  //   layout: "/dashboard",
  // },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    icon: "ni ni-active-40 text-pink",
    component: ForgotPassword,
    layout: "/auth",
  },
];
export default routes;
